<template>
  <i v-bind="[item]" class="iconfont" :class="item.icon" @click="onclilck(item.clickEvent)"></i>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  methods: {
    onclilck(fun) {
      if (fun && typeof (fun) === 'function') {
        fun(this.$parent.$parent)
      }
    }
  }
}

</script>
